import React, { useEffect, useState } from 'react';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import LanguageSelect from '../../../components/languageSelect';
import 'react-toastify/dist/ReactToastify.css';
import intl from 'react-intl-universal';
import { formatDateDayMonthTime } from '../../../utils/date';
import { routeApi } from '../../../utils/api';
import InfoModal from './infoModal';
import ChangeDeliveryPointModalMobile from '../../../components/modals/changeDeliveryPointMobile';
import OtherOptionsModalMobile from '../../../components/modals/otherOptionsMobile';
import AddNoteModalMobile from '../../../components/modals/addNoteMobile';
import CancelOrderMobile from '../../../components/modals/cancelOrderMobile';
import FeedbackModalMobile from '../../../components/modals/feedbackModalMobile';
import MapBox from '../../../components/mapBox';

const MobileHeader = ({ order }) => {
   return (
      <div className="mobile-header">
         <div className="pos-abs" style={{ top: 10, left: 10, zIndex: 3 }}>
            <LanguageSelect placement="bottom" />
         </div>

         <div className="header-part-one">
            <div style={{ color: 'white', textAlign: 'center' }}>
               <div className="bold">{order.tracker_code}</div>
               <div>{intl.get('orderTrackCode')}</div>
            </div>
         </div>
         <div className="header-part-two">
            <div className="status-icon green">
               <div className="icon">
                  {' '}
                  <i className="las la-shipping-fast"></i>{' '}
               </div>
               <div className="text">
                  {' '}
                  <div>{order.status && intl.get(order.status)}</div>{' '}
               </div>
            </div>
            <div>
               <div style={{ color: '#006DFF' }} className="text-20 bold">
                  {formatDateDayMonthTime(order.estimated_start_time, false)}
               </div>
               <div style={{ color: '#8181A5', textAlign: 'right' }}>{intl.get('estimatedCompleteTime')}</div>
            </div>
         </div>
      </div>
   );
};

const Footer = ({ handleChangeDeliveryPoint, handleOtherOptions, isOrderCompleted, disabled }) => (
   <div className="mobile-footer">
      <div
         style={{ padding: '10px 20px', fontSize: 12 }}
         className={`btn color-p cursor-pointer ${isOrderCompleted || disabled ? 'btn-disabled' : ''} text-8`}
         onClick={handleChangeDeliveryPoint}
      >
         {intl.get('changeDeliveryPoint')}
      </div>
      <div
         style={{ padding: '10px 20px', color: 'black', fontSize: 13 }}
         className={`btn color-s ml-12 ${disabled ? 'btn-disabled' : ''}`}
         onClick={handleOtherOptions}
      >
         {intl.get('otherOptions')}
      </div>
   </div>
);

const MapView = ({ order, vehicleUsername, timeline, company, onRefresh, deliveryPointOptions }) => {
   const [isLockerLocationsActive, setIsLockerLocationsActive] = useState(false);
   const [isChangeDeliveryPointModalOpen, setIsChangeDeliveryPointModalOpen] = useState(false);
   const [isOtherOptionsOpen, setIsOtherOptionsOpen] = useState(false);
   const [noteModalOpen, setNoteModalOpen] = useState(false);
   const [modalOpenState, setModalOpenState] = useState(-1); // -1:none 0:addnote 1:change-date 2:mobile-pay 3:cust-feedback 4:cancel
   const [cancelOrderModalOpen, setCancelOrderModalOpen] = useState(false);
   const [rateDeliveryModalOpen, setRateDeliveryModalOpen] = useState(false);
   const [formList, setFormList] = useState([]);
   const [feedbackAnswers, setFeedbackAnswers] = useState(null);
   useEffect(() => {
      switch (modalOpenState) {
         case 1:
            setNoteModalOpen(true);
            break;
         case 4:
            setRateDeliveryModalOpen(true);
            break;
         case 5:
            setCancelOrderModalOpen(true);
            break;
         default:
            break;
      }
   }, [modalOpenState]);

   useEffect(() => {
      routeApi()
         .get('/company/setting-feedback/', {}, company.name)
         .then((response) => {
            if (response.length > 0 && response[0].value.length > 0) {
               setFormList(response[0].value);
               let temp = {};
               response[0].value.map((item) => {
                  temp = {
                     ...temp,
                     [item.q]: {
                        ...item,
                        answer: item.type === 'boolean' ? false : item.type === 'text' ? '' : 0,
                     },
                  };
               });
               setFeedbackAnswers(temp);
            } else {
               setFeedbackAnswers(null);
               setFormList(null);
            }
         });
   }, [order]);

   return (
      <div className="flex-1 map-view">
         <MobileHeader order={order} />
         <div className="pos-rel" style={{ height: window.innerHeight - 207 }}>
            <MapBox
               companyName={company.name}
               order={order}
               vehicleUsername={vehicleUsername}
               isLockerLocationsActive={isLockerLocationsActive}
               setIsLockerLocationsActive={setIsLockerLocationsActive}
               onRefresh={onRefresh}
            />
         </div>
         <InfoModal order={order} timeline={timeline} />

         <Footer
            handleChangeDeliveryPoint={() => setIsChangeDeliveryPointModalOpen(true)}
            handleOtherOptions={() => setIsOtherOptionsOpen(true)}
            isOrderCompleted={order.status === 'completed' || order.status === 'canceled'}
            disabled={isLockerLocationsActive}
         />
         <ToastContainer
            position="top-center"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            transition={Zoom}
         />
         <ChangeDeliveryPointModalMobile
            isOpen={isChangeDeliveryPointModalOpen}
            deliveryPointOptions={deliveryPointOptions}
            handleClose={(done, error, lockerSelect) => {
               if (lockerSelect) {
                  setIsLockerLocationsActive(true);
                  setIsChangeDeliveryPointModalOpen(false);
                  return;
               }
               if (error) {
                  toast.error(intl.get('errorMessage'));
                  return;
               }
               setIsChangeDeliveryPointModalOpen(false);
               if (done) {
                  toast.success(intl.get('noteAddedMessage'));
               }
            }}
            order={order}
            company={company}
         />
         <AddNoteModalMobile
            order={order}
            company={company}
            isOpen={noteModalOpen}
            handleClose={(done, error) => {
               if (error) {
                  toast.error(intl.get('errorMessage'));
                  return;
               }
               setNoteModalOpen(false);
               setModalOpenState(-1);
               if (done) {
                  toast.success(intl.get('noteAddedMessage'));
               }
            }}
         />
         <FeedbackModalMobile
            isOpen={rateDeliveryModalOpen}
            order={order}
            formList={formList}
            feedbackAnswers={feedbackAnswers}
            setFeedbackAnswers={setFeedbackAnswers}
            handleClose={(done, error) => {
               if (error) {
                  toast.error(intl.get('errorMessage'));
                  return;
               }
               setRateDeliveryModalOpen(false);
               setModalOpenState(-1);
               if (done) {
                  onRefresh();
               }
            }}
         />
         <CancelOrderMobile
            order={order}
            company={company}
            isOpen={cancelOrderModalOpen}
            handleClose={(done, error) => {
               if (error) {
                  toast.error(intl.get('errorMessage'));
                  return;
               }
               setCancelOrderModalOpen(false);
               setModalOpenState(-1);
               if (done) {
                  toast.success(intl.get('orderCanceledMessage'));
                  onRefresh();
               }
            }}
         />
         <OtherOptionsModalMobile
            isOrderCompleted={order.status === 'completed' || order.status === 'canceled'}
            feedbackDisable={formList}
            isOpen={isOtherOptionsOpen}
            handleClose={() => {
               setIsOtherOptionsOpen(false);
            }}
            onSelect={(state) => {
               setModalOpenState(state);
               setIsOtherOptionsOpen(false);
            }}
         />
      </div>
   );
};

export default MapView;
