/* eslint-disable */
import React, { useState } from "react";
import { Popover, ArrowContainer } from "react-tiny-popover";
import intl from "react-intl-universal";

export const MoreActions = ({
  arrow,
  children,
  setOpenModal, // -1:none 0:addnote 1:change-date 2:mobile-pay 3:cust-feedback 4:cancel
  isOrderCompleted,
  feedbackDisable,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  function actionList(nudgedTop, position, childRect, popoverRect) {
    return (
      <ArrowContainer
        position={position}
        childRect={childRect}
        popoverRect={popoverRect}
        arrowColor="#fff"
        arrowSize={7}
        arrowStyle={{ opacity: arrow ? 1 : 0 }}
        arrowClassName="popover-arrow"
      >
        <div // nudget top ile değişiklik yap arrowda condition içinde olsun
          className="popover-container"
          style={{
            visibility:
              nudgedTop < -100 || nudgedTop > 0 ? "hidden" : "visible",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <a
            className={`cursor-pointer ${
              isOrderCompleted ? "btn-disabled" : ""
            }`}
            onClick={() => {
              setOpenModal(1);
              setIsPopoverOpen(false);
            }}
          >
            <i className="las la-sticky-note" />
            <span>{intl.get("addNote")}</span>
          </a>
          <a
            className={`cursor-pointer btn-disabled`}
            onClick={() => {
              setOpenModal(2);
              setIsPopoverOpen(false);
            }}
          >
            <i className="las la-calendar" />
            <span>{intl.get("changeDeliveryDate")}</span>
          </a>
          <a
            className={`cursor-pointer btn-disabled`}
            onClick={() => {
              setOpenModal(3);
              setIsPopoverOpen(false);
            }}
          >
            <i className="las la-credit-card" />
            <span>{intl.get("mobilePayment")}</span>
          </a>
          <a
            className={`cursor-pointer ${
              !isOrderCompleted || !feedbackDisable ? "btn-disabled" : ""
            }`}
            onClick={() => {
              setOpenModal(4);
              setIsPopoverOpen(false);
            }}
          >
            <i className="las la-thumbs-up" />
            <span>{intl.get("rateDelivery")}</span>
          </a>
          <a
            className={`cursor-pointer ${
              isOrderCompleted ? "btn-disabled" : ""
            }`}
            onClick={() => {
              setOpenModal(5);
              setIsPopoverOpen(false);
            }}
            style={{ color: "#E02020" }}
          >
            <i className="las la-calendar-times" />
            <span>{intl.get("cancelDelivery")}</span>
          </a>
        </div>
      </ArrowContainer>
    );
  }

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={["top"]}
      align="end"
      padding={0}
      containerStyle={{ zIndex: 1002 }}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={({ nudgedTop, position, childRect, popoverRect }) =>
        actionList(nudgedTop, position, childRect, popoverRect)
      }
    >
      <div
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          setIsPopoverOpen(true);
        }}
      >
        {children}
      </div>
    </Popover>
  );
};
