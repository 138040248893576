/* eslint-disable */
import { React } from 'react';
/*
    Icon svg goes here
*/
export const circleSvg = (color) => `    
    <svg class="pulse-svg" width="25px" height="25px" viewBox="0 0 50 50" version="1.1" 
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <circle class="circle first-circle" fill="#185df6" cx="25" cy="25" r="15"></circle>
        <circle class="circle second-circle" fill="${color}" cx="25" cy="25" r="15"></circle>
        <circle class="circle third-circle" fill="#185df6" cx="25" cy="25" r="15"></circle>
        <circle class="circle" fill="#185df6" cx="25" cy="25" r="10"></circle>
    </svg>
`;

export const truckIcon = (color, text = '') => `
  <svg height="40px" width="40px" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(8 , 5)" fill="none">
      <path d="M19 31c0 1.7-2.7 3-6 3s-6-1.3-6-3 2.7-3 6-3 6 1.3 6 3z" fill-opacity=".2" fill="#000"/>
      <path d="M13 0C9.5 0 6.3 1.3 3.8 3.8c-2.4 4-3.8 5.6-3.8 9 0 3.5 1.4 6.7 3.8 9.1L13 31l9.2-9.1c2.4-2.4 3.7-5.6 3.7-9.1 0-3.4-1.3-6.7-3.8-9C19.7 1.3 16.5 0 13 0z" fill="${color}"/>
      <path d="M13 2.2c-7 0-10.7 5-10.9 10.6 0 3.3 1 5.6 3.1 7.7l7.8 7.7 7.8-7.7c2.1-2.1 3-4.3 3-7.7C23.6 7.07 20 2.2 13 2.2z" fill="${color}"/>
      <path d="M18.725 15.563h-.275V13.24c0-.273-.088-.535-.242-.728l-1.717-2.147a.752.752 0 0 0-.583-.303h-.758v-1.03c0-.57-.37-1.032-.825-1.032h-5.5C8.37 8 8 8.462 8 9.031v6.875c0 .57.37 1.031.825 1.031H9.1c0 1.14.74 2.063 1.65 2.063.91 0 1.65-.924 1.65-2.063h2.2c0 1.14.74 2.063 1.65 2.063.91 0 1.65-.924 1.65-2.063h.825c.151 0 .275-.154.275-.343v-.688c0-.189-.124-.344-.275-.344zm-7.975 2.406c-.455 0-.825-.462-.825-1.032 0-.569.37-1.03.825-1.03.455 0 .825.461.825 1.03 0 .57-.37 1.032-.825 1.032zm5.5 0c-.455 0-.825-.462-.825-1.032 0-.569.37-1.03.825-1.03.455 0 .825.461.825 1.03 0 .57-.37 1.032-.825 1.032zm1.375-4.469H15.15v-2.406h.758l1.717 2.146v.26z" fill="#FFF"/>
    </g>
  </svg>
`;

export const homeIcon = (color) => `
  <svg height="50px" width="50px" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
  <g transform="translate(7 , 4)" fill="${color}">
      <path d="M19 31c0 1.7-2.7 3-6 3s-6-1.3-6-3 2.7-3 6-3 6 1.3 6 3z" fill-opacity=".2" fill="#000"/>
      <path d="M13 0C9.5 0 6.3 1.3 3.8 3.8c-2.4 4-3.8 5.6-3.8 9 0 3.5 1.4 6.7 3.8 9.1L13 31l9.2-9.1c2.4-2.4 3.7-5.6 3.7-9.1 0-3.4-1.3-6.7-3.8-9C19.7 1.3 16.5 0 13 0z" fill="${color}"/>
      <path d="M13 2.2c-7 0-10.7 5-10.9 10.6 0 3.3 1 5.6 3.1 7.7l7.8 7.7 7.8-7.7c2.1-2.1 3-4.3 3-7.7C23.6 7.07 20 2.2 13 2.2z" fill="${color}"/>
    <path transform="translate(1 , 1)" fill="#fff" xmlns="http://www.w3.org/2000/svg" d="m12.48 5.085-.345.33-6.24 6.24.69.69.615-.615v5.55h4.32v-4.8h1.92v4.8h4.32v-5.55l.615.615.69-.69-6.24-6.24Zm0 1.365 4.32 4.32v5.55h-2.4v-4.8h-3.84v4.8h-2.4v-5.55Z"/>
  </g>
  </svg>
`;
export const lockerIcon = (color) => `
  <svg height="40px" width="40px" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(7 , 4)" fill="${color}">
        <path d="M19 31c0 1.7-2.7 3-6 3s-6-1.3-6-3 2.7-3 6-3 6 1.3 6 3z" fill-opacity=".2" fill="#000"/>
        <path d="M13 0C9.5 0 6.3 1.3 3.8 3.8c-2.4 4-3.8 5.6-3.8 9 0 3.5 1.4 6.7 3.8 9.1L13 31l9.2-9.1c2.4-2.4 3.7-5.6 3.7-9.1 0-3.4-1.3-6.7-3.8-9C19.7 1.3 16.5 0 13 0z" fill="${color}"/>
        <path d="M13 2.2c-7 0-10.7 5-10.9 10.6 0 3.3 1 5.6 3.1 7.7l7.8 7.7 7.8-7.7c2.1-2.1 3-4.3 3-7.7C23.6 7.07 20 2.2 13 2.2z" fill="${color}"/>
    <path fill="#fff" transform="scale(.03) translate(180, 130)" d="M25 73v414h142V73H25zm160 0v414h142V73H185zm160 0v414h142V73H345zM39 112h18v23h78v-23h18v41H39v-41zm160 0h18v23h78v-23h18v41H199v-41zm160 0h18v23h78v-23h18v41H359v-41zM39 176h18v23h78v-23h18v41H39v-41zm160 0h18v23h78v-23h18v41H199v-41zm160 0h18v23h78v-23h18v41H359v-41zM64 279c13.7 0 25 11.3 25 25s-11.3 25-25 25-25-11.3-25-25 11.3-25 25-25zm160 0c13.7 0 25 11.3 25 25s-11.3 25-25 25-25-11.3-25-25 11.3-25 25-25zm160 0c13.7 0 25 11.3 25 25s-11.3 25-25 25-25-11.3-25-25 11.3-25 25-25zM64 297c-3.97 0-7 3-7 7s3.03 7 7 7 7-3 7-7-3.03-7-7-7zm160 0c-4 0-7 3-7 7s3 7 7 7 7-3 7-7-3-7-7-7zm160 0c-4 0-7 3-7 7s3 7 7 7 7-3 7-7-3-7-7-7zM48 407h96v18H48v-18zm160 0h96v18h-96v-18zm160 0h96v18h-96v-18zM48 441h96v18H48v-18zm160 0h96v18h-96v-18zm160 0h96v18h-96v-18z"/>
    </g>
  </svg>
`;

export const standartIcon = (color, text, textColor = '#fff') => `
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50%" cy="50%" r="11" stroke="${textColor}" fill="${color}"/>
    <text x="48%" y="50%" text-anchor="middle" fill="${textColor}" dy=".3em" font-size="12" font-weight="200" font-family="Roboto, sans-serif">
      ${text}
    </text>
  </svg>
`;
export const HomeIcon = (color) => `
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50%" cy="50%" r="11" stroke="#FFF" fill="${color}"/>
    <path fill="#fff" xmlns="http://www.w3.org/2000/svg" d="m12.48 5.085-.345.33-6.24 6.24.69.69.615-.615v5.55h4.32v-4.8h1.92v4.8h4.32v-5.55l.615.615.69-.69-6.24-6.24Zm0 1.365 4.32 4.32v5.55h-2.4v-4.8h-3.84v4.8h-2.4v-5.55Z"/>
  </svg>
`;
export const SmallCircleIcon = (color) => `
  <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50%" cy="50%" r="5" stroke="#FFF" fill="${color}"/>
  </svg>
`;
export const HomeIconText = (color, text) => `
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
  <text x="50%" y="40%" fill="#185DF6" font-family="Roboto, Helvetica, sans-serif" font-size="12" text-anchor="middle" >${text}</text><g transform="translate(63 , 26)" fill="none">
    <circle cx="50%" cy="50%" r="11" stroke="#FFF" fill="${color}"/>
    <path fill="#fff" xmlns="http://www.w3.org/2000/svg" d="m12.48 5.085-.345.33-6.24 6.24.69.69.615-.615v5.55h4.32v-4.8h1.92v4.8h4.32v-5.55l.615.615.69-.69-6.24-6.24Zm0 1.365 4.32 4.32v5.55h-2.4v-4.8h-3.84v4.8h-2.4v-5.55Z"/>
  </svg>
`;
export const endIcon = (color) => `
  <svg width="23" height="22" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(.3)" fill="none" fill-rule="evenodd" stroke="#fff" stroke-width=".5">
      <circle fill="${color}" cx="11.7" cy="12" r="10"/>
      <path stroke="#fff" transform="scale(.9) translate(1,1)" d="M17.71 9.262c.078-.227.085-.6.016-.828l-.23-.755c-.07-.229-.38-.449-.688-.489l-2.12-.274a5.151 5.151 0 0 0-1.121.003l-2.296.31a5.426 5.426 0 0 1-1.124.01l-2.246-.263c.002.008.007.015.01.023l1.538 5.471.703.092c.309.04.813.038 1.122-.003l2.296-.31a5.448 5.448 0 0 1 1.123-.01l2.465.288c.31.036.516-.123.46-.354l-.277-1.119c-.057-.23-.039-.605.039-.832l.33-.96zM6.68 6.758c-.309.05-.506.275-.441.505L9.52 18.937h1.164L7.354 7.088c-.066-.231-.367-.378-.675-.33z" stroke-width=".5" fill="#fff"/>
    </g>
  </svg>
`;

export const startIcon = (color) => `
  <svg width="23" height="22" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(.3)" fill="none" fill-rule="evenodd" stroke="#fff" stroke-width=".5">
      <circle fill="${color}" cx="11.7" cy="12" r="10"/>
      <path d="M7.146 10.24h3.403L11.6 7.073l1.051 3.167h3.403L13.3 12.197l1.051 3.167-2.752-1.957-2.752 1.957 1.05-3.167-2.752-1.957z" stroke-width="1.35" fill="#fff"/>
    </g>
  </svg>
`;

export const rectangleSvg = (border = '#bebebe') => `
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" style="fill:${border};stroke-width:2;stroke:white" />
    </svg>
`;

export const StandartIcons = ({ color, text }) => (
   <svg width="23" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill={color}>
         <circle cx="11.345" cy="10.989" r="10.977" stroke="#FFF" />
         <text x="48%" y="50%" textAnchor="middle" fill="#fff" dy=".3em" fontSize="11">
            {text}
         </text>
      </g>
   </svg>
);

export const HomeIconJsx = (color) => (
   <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50%" cy="50%" r="11" stroke="#FFF" fill="${color}" />
      <path
         fill="#fff"
         xmlns="http://www.w3.org/2000/svg"
         d="m12.48 5.085-.345.33-6.24 6.24.69.69.615-.615v5.55h4.32v-4.8h1.92v4.8h4.32v-5.55l.615.615.69-.69-6.24-6.24Zm0 1.365 4.32 4.32v5.55h-2.4v-4.8h-3.84v4.8h-2.4v-5.55Z"
      />
   </svg>
);

export const PlayButton = () => (
   <svg width="72px" height="72px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50%" cy="50%" r="9" stroke="#fff" stroke-width="2" fill="#195ef6" />
      <path
         fill="#fff"
         d="M10.69 17.23C10.1596 17.23 9.65086 17.0193 9.27579 16.6442C8.90071 16.2692 8.69 15.7604 8.69 15.23V9.23001C8.69649 8.86957 8.80028 8.51759 8.9904 8.21129C9.18051 7.905 9.44988 7.65578 9.77 7.49001C10.0924 7.32572 10.4533 7.25197 10.8144 7.27662C11.1754 7.30128 11.5229 7.42341 11.82 7.63001L16.11 10.63C16.3661 10.8154 16.5746 11.0589 16.7184 11.3405C16.8622 11.6221 16.9371 11.9338 16.9371 12.25C16.9371 12.5662 16.8622 12.8779 16.7184 13.1595C16.5746 13.4411 16.3661 13.6846 16.11 13.87L11.82 16.87C11.4884 17.1015 11.0944 17.227 10.69 17.23ZM10.69 8.77001C10.6139 8.77005 10.5387 8.78713 10.47 8.82001C10.3912 8.86067 10.3251 8.92237 10.2792 8.99827C10.2333 9.07418 10.2094 9.16131 10.21 9.25001V15.25C10.2094 15.3387 10.2333 15.4258 10.2792 15.5018C10.3251 15.5777 10.3912 15.6394 10.47 15.68C10.5425 15.7286 10.6278 15.7545 10.715 15.7545C10.8022 15.7545 10.8875 15.7286 10.96 15.68L15.25 12.68C15.3139 12.637 15.3663 12.579 15.4025 12.5109C15.4388 12.4429 15.4577 12.3671 15.4577 12.29C15.4577 12.213 15.4388 12.1371 15.4025 12.0691C15.3663 12.0011 15.3139 11.943 15.25 11.9L11 8.86001C10.9118 8.79182 10.801 8.75966 10.69 8.77001Z"
      />
   </svg>
);
