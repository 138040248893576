/* eslint-disable */
/* eslint-disable implicit-arrow-linebreak */

/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
import store from 'store';
import moment from 'moment-timezone';
import 'moment/locale/tr';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/pt';
import 'moment/locale/it';

//     "timeZone": "Europe/Istanbul"
moment.tz.setDefault('Europe/Istanbul');
const locales = {
   'en-US': 'en',
   'tr-TR': 'tr',
   'es-ES': 'es',
   'fr-FR': 'fr',
   'de-DE': 'de',
   'pt-PT': 'pt',
   'it-IT': 'it',
   'zh-CN': 'en',
};
const rootFormatter = (date, formatString, timeZone) => {
   const l = store.get('locale');
   moment.locale(locales[l]);
   const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
   if (date) {
      let formattedDate;
      if (timeZone) {
         formattedDate = moment(date).tz(tz).format(formatString);
      } else {
         formattedDate = moment.parseZone(date).format(formatString);
      }
      if (formattedDate === 'Invalid date') return '?';
      return formattedDate;
   }
};
export const formatDateDayMonthTime = (date, timezone = true) => rootFormatter(date, 'D MMMM - HH:mm', timezone);
export const formatDateLongMonthWithTime = (date, timezone = true) =>
   rootFormatter(date, 'dd MMMM yyyy, HH:mm', timezone);
export const formatDateShortMonthWithTime = (date, timezone = true) =>
   rootFormatter(date, 'dd MMM yyyy, HH:mm', timezone);
export const formatDateShortMonthNoYearWithTime = (date, timezone = true) =>
   rootFormatter(date, 'dd MMM yy, HH:mm', timezone);
export const formatDateNoTimeNoYear = (date, timezone = true) => rootFormatter(date, 'dd MMM', timezone);
export const formatDateReverseNoTime = (date, timezone = true) => rootFormatter(date, 'yyyy-MM-dd', timezone);
export const formatDateNoTime = (date, timezone = true) => rootFormatter(date, 'dd MMM yyyy', timezone);
export const formatDateLongMonthNoTime = (date, timezone = true) => rootFormatter(date, 'dd MMMM yyyy', timezone);
export const formatDateOnlyTimeNoSeconds = (date, timezone = true) => rootFormatter(date, 'HH:mm', timezone);
export const formatDateWithDots = (date, timezone = true) => rootFormatter(date, 'dd.MM.yyyy', timezone);
export const formatDateWithHyphen = (date, timezone = true) => rootFormatter(date, 'dd-MM-yyyy', timezone);
export const formatDateWithHyphenReverse = (date, timezone = true) => rootFormatter(date, 'yyyy-MM-dd', timezone);
export const formatDateNoTimePointNotation = (date, timezone = true) => rootFormatter(date, 'dd.MM.yyyy', timezone);
export const formatTimeWindowViolation = (time) => {
   const violation = time.split(':').map((i) => parseInt(i, 10));
   return {
      ...(violation[0] && { day: violation[0] }),
      ...(violation[1] && { hour: violation[1] }),
      ...(violation[2] && { minute: violation[2] }),
   };
};
