import React from 'react';

const FormInput = ({
   register,
   name,
   iconClassName,
   label,
   disabled = false,
   outerClassName = 'column',
   className = 'form-input',
   placeholder = 'Lütfen giriniz',
   labelClassName,
   validation = {},
   error,
   defaultValue,
   errorClassName = 'error',
   errorMessage = 'Bu alan gerekli',
   ...rest
}) => (
   <div className={outerClassName}>
      <label className={labelClassName}>{label}</label>
      <input
         readOnly={disabled}
         {...register(name, validation)}
         placeholder={placeholder}
         className={className}
         defaultValue={defaultValue}
         {...rest}
      />
      <i className={`las ${iconClassName}`} />
      {error && <span className={errorClassName}>{errorMessage}</span>}
   </div>
);

export default FormInput;
