import React, { useEffect } from 'react';
import './styles/style.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Track from './views/track';
import LocaleEN from './lang/en.json';
import LocaleTR from './lang/tr.json';
import LocaleNL from './lang/nl.json';
import LocaleES from './lang/es.json';
import LocaleDE from './lang/de.json';
import LocalePT from './lang/pt.json';
import LocaleIT from './lang/it.json';
import LocaleFR from './lang/fr.json';
import LocaleRU from './lang/ru.json';
import LocalePL from './lang/pl.json';
import LocaleID from './lang/id.json';
import LocaleSV from './lang/sv.json';
import LocaleCH from './lang/zh_Hans_CN.json';

import intl from 'react-intl-universal';
import store from 'store';
import MapWidget from './views/widget';

const locales = {
   'en-US': LocaleEN,
   'tr-TR': LocaleTR,
   'es-ES': LocaleES,
   'de-DE': LocaleDE,
   'pt-PT': LocalePT,
   'it-IT': LocaleIT,
   'fr-FR': LocaleFR,
   'ru-RU': LocaleRU,
   'pl-PL': LocalePL,
   'zh-CN': LocaleCH,
   'id-ID': LocaleID,
   'sv-SV': LocaleSV,
   'nl-NL': LocaleNL,
};
function App() {
   useEffect(() => {
      const storedLang = store.get('locale');
      let userLang =
         storedLang || window.navigator.userLanguage || window.navigator.language || process.env.REACT_APP_DEFAULT_LANG;
      store.set('locale', userLang);
      intl.init({ currentLocale: userLang, locales });
   }, []);
   return (
      <>
         <Router>
            <React.Suspense /* loading={loading} fallback={<LoadingAnimation />} */>
               <Routes>
                  <Route exact={true} path="/" element={<Track />} />
                  <Route exact path="/widget" element={<MapWidget />} />
               </Routes>
            </React.Suspense>
         </Router>
      </>
   );
}

export default App;
