/* eslint-disable */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import io from 'socket.io-client';
import { routeApi } from '../../utils/api';
import { encryptText, decryptSocketData } from '../../utils/helpers';
import { homeIcon, truckIcon } from '../../components/HereMap/iconSvg';
import { centerMapToGroup } from '../../components/HereMap/mapFunctions';
import HereMapContainer from '../../components/HereMap/HereMapContainer';

const { H } = window;
const MapBox = ({ order, vehicleUsername, searchParams }) => {
   const newSocket = useMemo(
      () =>
         io(process.env.REACT_APP_NEW_SOCKET_URL, {
            path: '/websocket',
            auth: {
               token: `token ${process.env.REACT_APP_SOCKET_USER_TOKEN}`,
            },
         }),
      []
   );
   const group = useRef(new H.map.Group());
   useEffect(() => {
      if (order.lat) {
         group.current.removeObjects(group.current.getObjects());
         createHomeMarker(window.MapObject);
         createTruckMarker(window.MapObject);
         centerMapToGroup(group.current);
      }
   }, [order]);
   const createHomeMarker = (map) => {
      const homeMarker = new H.map.Marker(
         { lat: order.lat, lng: order.lon },
         {
            icon: new H.map.Icon(homeIcon('#006DFF').trim()),
         }
      );
      group.current.addObject(homeMarker);
   };
   const createTruckMarker = (map) => {
      const truck = new H.map.Marker(
         order.current_lat ? { lat: order.current_lat, lng: order.current_lon } : { lat: 41.3, lng: 28.2 },
         {
            icon: new H.map.Icon(searchParams.get('marker') || truckIcon('#000').trim()),
         }
      );
      group.current.addObject(truck);
      const channel = encryptText(`track-${vehicleUsername}`, process.env.REACT_APP_API_DECYRPT_KEY);

      newSocket.emit('join-room', vehicleUsername);
      newSocket.on(channel, (res) => {
         const data = decryptSocketData(res, process.env.REACT_APP_API_DECYRPT_KEY);
         truck.setGeometry({ lat: data.latitude, lng: data.longitude });
      });
   };
   return (
      <HereMapContainer
         onSuccess={(mapObj) => {
            window.MapObject = mapObj;
            window.MapObject.addObject(group.current);
         }}
         zoom={10}
         useEvents
         useUi
      />
   );
};

const MapWidget = () => {
   const [data, setData] = useState({
      result: [{}],
      status: [],
   });
   const [searchParams, setSearchParams] = useSearchParams();
   const getData = () => {
      const token = searchParams.get('token');
      if (token) {
         routeApi()
            .get(`/order/tracking-info/?token=${token}`)
            .then((response) => {
               if (response) {
                  setData(response);
               }
            })
            .catch((error) => {
               console.log(error);
            });
      }
   };
   useEffect(() => {
      getData();
   }, []);
   return (
      <div
         style={{
            height: searchParams.get('height') || 500,
            width: searchParams.get('width') || '100%',
         }}
      >
         <MapBox order={data.result[0]} vehicleUsername={data.username} searchParams={searchParams} />
      </div>
   );
};

export default MapWidget;
