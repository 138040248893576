/* eslint-disable */
import React, { useEffect, useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";
import { withResizeDetector } from "react-resize-detector";

const { H } = window;
/*
  This component passes the map, ui, behavior objects as props directly to its children
  takes center, zoom, styles as parameters
  useUi === true => mapSettings, scaleBar, zoomControl will be visible
  onSuccess => callback with map object as param
*/
// targetRef, width and height props comes from withResizeDetector used as higher order component
const HereMapContainer = ({
  targetRef,
  width,
  height,
  center,
  zoom,
  styles,
  useEvents,
  useUi,
  onSuccess,
}) => {
  const map = useRef();
  useLayoutEffect(() => {
    if (!targetRef.current) return;
    const platform = new H.service.Platform({
      apikey: process.env.REACT_APP_HERE_KEY,
      useHTTPS: true,
      app_id: process.env.REACT_APP_HERE_ID,
    });
    window.Platform = platform;
    const defaultLayers = platform.createDefaultLayers({ ppi: 72 });

    const hMap = new H.Map(
      targetRef.current,
      defaultLayers.raster.terrain.map,
      {
        center,
        zoom,
        pixelRatio: window.devicePixelRatio || 1,
        padding: { top: 100, left: 100, bottom: 130, right: 100 },
      }
    );
    map.current = hMap;

    if (useEvents) {
      const behavior = new H.mapevents.Behavior(
        new H.mapevents.MapEvents(hMap)
      );
    }
    if (useUi) {
      const ui = H.ui.UI.createDefault(hMap, defaultLayers, "tr-TR");
      ui.removeControl("mapsettings");
      ui.removeControl("scalebar");

      // positioning ui elements
      ui.getControl("zoom").setAlignment("right-bottom");
    }
    onSuccess(hMap);
    return () => {
      hMap.dispose();
    };
  }, []);
  useEffect(() => {
    if (map.current) map.current.getViewPort().resize();
  }, [width, height]);
  return (
    <div
      id="map-container"
      className="map"
      ref={targetRef}
      style={{
        ...styles,
      }}
    />
  );
};
HereMapContainer.defaultProps = {
  center: {
    lat: 41.03115,
    lng: 29.00214,
  },
  zoom: 13,
  useEvents: true,
  useUi: true,
  uiInstance: null,
  setUiInstance: null,
  shouldMapResize: true,
  onSuccess: (map) =>
    console.log("onSuccess default prop, map object => ", map),
};
HereMapContainer.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  uiInstance: PropTypes.object,
  setUiInstance: PropTypes.func,
  zoom: PropTypes.number,
  useEvents: PropTypes.bool,
  useUi: PropTypes.bool,
  onSuccess: PropTypes.func,
  shouldMapResize: PropTypes.bool,
};

export default withResizeDetector(HereMapContainer);
