import React from 'react';
import Infobar from '../../../components/infobar';
import MapView from './mapView';

const WebView = ({ data, onRefresh }) => {
   return (
      <div className="d-flex">
         <Infobar
            order={data.result[0]}
            timeline={data.status}
            company={{
               id: data.company_id,
               name: data.company_name,
            }}
         />
         <MapView
            order={data.result[0]}
            vehicleUsername={data.username}
            onRefresh={onRefresh}
            company={{
               id: data.company_id,
               name: data.company_name,
            }}
            deliveryPointOptions={data.delivery_point_options}
         />
      </div>
   );
};

export default WebView;
