/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { routeApi } from '../../utils/api';
import WebView from './web';

import intl from 'react-intl-universal';
import MediaQuery from 'react-responsive';
import MobileView from './mobile';
import { useLocation } from 'react-router-dom';
import LogoIcon from '../../assets/logo-icon.svg';
import LogoText from '../../assets/optiyol-text-gray.svg';
const Track = () => {
   const [data, setData] = useState({
      result: [{}],
      status: [],
   });
   const [wrongToken, setWrongToken] = useState(false);
   const location = useLocation();
   const getData = () => {
      const token = location.search && location.search.slice(7);
      if (token) {
         routeApi()
            .get(`/order/tracking-info/?token=${token}`)
            .then((response) => {
               if (response) {
                  setData(response);
               }
            })
            .catch((error) => {
               setWrongToken(true);
               console.log(error);
            });
      } else {
         setWrongToken(true);
      }
   };
   const onRefresh = () => {
      getData();
   };
   useEffect(() => {
      getData();
   }, []);
   return (
      <div>
         {wrongToken ? (
            <div className="d-flex align-center justify-center col h-100">
               <div className="d-flex align-center justify-center">
                  <img src={LogoIcon} alt="optiyol" className="optiol-logo" width="60" height="60" loading="lazy" />
                  <img
                     src={LogoText}
                     alt="optiyol"
                     className="optiol-logo ml-20"
                     width="165"
                     height="60"
                     loading="lazy"
                  />
               </div>
               <div className="text-20 mt-16">{intl.get('orderNotFound')}</div>
            </div>
         ) : (
            <>
               <MediaQuery query={'(max-width: 1124px)'}>
                  <MobileView data={data} onRefresh={onRefresh} />
               </MediaQuery>
               <MediaQuery query={'(min-width: 1124px)'}>
                  <WebView data={data} onRefresh={onRefresh} />
               </MediaQuery>
            </>
         )}
      </div>
   );
};

export default Track;
