import React, { useMemo } from "react";
import { MobileModalLayout } from ".";
import intl from "react-intl-universal";

const Status = ({ status, icon, disabled, onSelect, index, iconBoxStyle }) => {
  return (
    <div>
      <div
        role="button"
        tabIndex={0}
        aria-label="modal-button"
        onClick={() => {
          onSelect(index + 1);
        }}
        className={`option-select  ${disabled ? "btn-disabled" : ""} mt-8`}
      >
        <div className={`mr-12 bold text-20 icon`} style={iconBoxStyle}>
          {icon}
        </div>
        <div className={` bold`}>{intl.get(status)}</div>
      </div>
    </div>
  );
};

const OtherOptionsModalMobile = ({
  handleClose,
  isOpen,
  onSelect,
  isOrderCompleted,
  feedbackDisable,
}) => {
  const statusOptions = useMemo(
    () => [
      {
        status: "addNoteCamel",
        iconClassName: "la-sticky-note",
        disabled: isOrderCompleted,
      },
      {
        status: "changeDeliveryDate",
        iconClassName: "la-calendar-week",
        disabled: true,
      },
      {
        status: "mobilePayment",
        iconClassName: "la-credit-card",
        disabled: true,
      },
      {
        status: "rateDelivery",
        iconClassName: "la-thumbs-up",
        disabled: !isOrderCompleted || !feedbackDisable,
      },
      {
        status: "cancelDelivery",
        iconClassName: "la-calendar-times",
        iconBoxStyle: { background: "rgba(235,94,85,0.1)", color: "#EB5E55" },
        iconStyle: { color: "#EB5E55" },
        disabled: isOrderCompleted,
      },
    ],
    []
  );
  return (
    <MobileModalLayout closeModal={handleClose} isModalOpen={isOpen}>
      <div
        className="modal-scrollable"
        style={{ height: document.body.clientHeight - 120 }}
      >
        <h3 className="bold mb-20">{intl.get("otherOptions")}</h3>
        {statusOptions.map((item, index) => (
          <Status
            status={item.status}
            index={index}
            icon={
              <i
                className={`las ${item.iconClassName}`}
                style={item.iconStyle}
              />
            }
            disabled={item.disabled}
            onSelect={onSelect}
            iconBoxStyle={item.iconBoxStyle}
          />
        ))}
      </div>
    </MobileModalLayout>
  );
};

export default OtherOptionsModalMobile;
