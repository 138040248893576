/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { ModalLayout } from '.';
import Switch from 'react-switch';
import { Controller, useForm } from 'react-hook-form';
import FormInput from '../formInput';
import { routeApi } from '../../utils/api';
import { PropagateLoader } from 'react-spinners';
import intl from 'react-intl-universal';
import Direct4MeLogo from '../../assets/IconDirect4me.png';
import { useLocation } from 'react-router-dom';

const Status = ({ children, status, icon, disabled, selectedStatus, setSelectedStatus }) => {
   return (
      <div>
         <div
            role="button"
            tabIndex={0}
            aria-label="modal-button"
            onClick={() => {
               setSelectedStatus(status);
            }}
            className={`status-select ${selectedStatus === status ? 'status-select-active' : ''} ${
               disabled ? 'btn-disabled' : ''
            } mt-8`}
         >
            <div className={`mr-12 ${selectedStatus === status ? 'text-green' : ''} bold text-20`}>{icon}</div>
            <div className={`${selectedStatus === status ? 'text-green' : ''} bold`}>{intl.get(status)}</div>
         </div>
         <AnimateHeight id="panel" duration={300} height={selectedStatus === status ? 'auto' : 0}>
            {children}
         </AnimateHeight>
      </div>
   );
};

const DeliveryToDoor = ({ register, control }) => (
   <div className="animated-form">
      <div className="d-flex align-center justify-space mb-10">
         <div>{intl.get('doNotRingBell')}</div>
         <Controller
            name="ringBell"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value, name, ref } }) => {
               return (
                  <Switch
                     onChange={(checked) => onChange(checked)}
                     checked={value}
                     offColor="#CDDDFE"
                     onColor="#195EF6"
                     height={15}
                     width={30}
                  />
               );
            }}
         />
      </div>
      <div className="d-flex align-center justify-space">
         <div>{intl.get('leaveAtDoor')}</div>
         <Controller
            name="leaveToDoor"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value, name, ref } }) => {
               return (
                  <Switch
                     onChange={(checked) => onChange(checked)}
                     checked={value}
                     offColor="#CDDDFE"
                     onColor="#195EF6"
                     height={15}
                     width={30}
                  />
               );
            }}
         />
      </div>
   </div>
);
const LeaveToSecurity = ({ register, control, errors, selectedStatus }) => (
   <div className="animated-form">
      <FormInput
         outerClassName="mt-12"
         labelClassName="text-mid-gray text-14"
         register={register}
         name="security"
         validation={{ required: selectedStatus === 'Güvenliğe Bırak' }}
         errorClassName="text-red text-12"
         label={intl.get('securityName')}
         placeholder={intl.get('pleaseEnter')}
      />
   </div>
);
const LeaveToLocker = () => (
   <div className="animated-form">
      <div className="btn small color-green">
         <img src={Direct4MeLogo} className="mr-8" style={{ height: 20 }} />
         Direct4Me
      </div>
   </div>
);
const LeaveToNeighbor = ({ register, control, errors, selectedStatus }) => (
   <div className="animated-form">
      <FormInput
         outerClassName="mt-12"
         labelClassName="text-mid-gray text-14"
         register={register}
         name="neighborName"
         validation={{ required: selectedStatus === 'Komşuma Bırak' }}
         errorClassName="text-red text-12"
         label={intl.get('neighborName')}
         placeholder={intl.get('pleaseEnter')}
      />
      <FormInput
         outerClassName="mt-12"
         labelClassName="text-mid-gray text-14"
         register={register}
         name="neighborDoorNumber"
         validation={{ required: selectedStatus === 'Komşuma Bırak' }}
         errorClassName="text-red text-12"
         label={intl.get('neighborDoorNumber')}
         placeholder={intl.get('pleaseEnter')}
      />
   </div>
);

const ChangeDeliveryPointModal = ({ handleClose, isOpen, order, company, deliveryPointOptions }) => {
   const [selectedStatus, setSelectedStatus] = useState('');
   const [isLoading, setIsLoading] = useState(false);
   const location = useLocation();
   const {
      register,
      handleSubmit,
      reset,
      control,
      formState: { errors },
   } = useForm();
   useEffect(() => {
      setSelectedStatus('');
      reset();
   }, [isOpen]);
   const [statusOptions, setStatusOptions] = useState([
      {
         status: 'deliveryToDoor',
         iconClassName: 'la-door-open',
         active: true,
         extraComponent: <DeliveryToDoor register={register} control={control} selectedStatus={selectedStatus} />,
      },
      {
         status: 'leaveToSecurity',
         iconClassName: 'la-user-shield',
         active: true,
         extraComponent: (
            <LeaveToSecurity register={register} control={control} errors={errors} selectedStatus={selectedStatus} />
         ),
      },
      {
         status: 'leaveToNeighbor',
         iconClassName: 'la-building',
         active: true,
         extraComponent: (
            <LeaveToNeighbor register={register} control={control} errors={errors} selectedStatus={selectedStatus} />
         ),
      },
      {
         status: 'leaveToLocker',
         iconClassName: 'la-box',
         active: true,
         extraComponent: <LeaveToLocker register={register} selectedStatus={selectedStatus} />,
      },
   ]);
   useEffect(() => {
      if (deliveryPointOptions) {
         const options = deliveryPointOptions.split(';');
         setStatusOptions((state) => {
            const tempState = [...state];
            // change active field
            return tempState.map((o) => {
               if (options.includes(o.status)) {
                  return { ...o, active: true };
               } else {
                  return { ...o, active: false };
               }
            });
         });
      }
   }, [deliveryPointOptions]);
   const onSubmit = (data) => {
      if (selectedStatus === 'deliveryToDoor' && !(data.ringBell || data.leaveToDoor)) return;
      const token = location.search && location.search.slice(7);
      const payload = {
         company_id: company.id,
         customer_note:
            selectedStatus === 'leaveToSecurity'
               ? `Güvenliğe Bırak. (İsim: ${data.security || ''})`
               : selectedStatus === 'leaveToNeighbor'
               ? `Komşuma Bırak. (İsim: ${data.neighborName || ''} - Kapı Numarası: ${data.neighborDoorNumber || ''})`
               : selectedStatus === 'deliveryToDoor' &&
                 `${data.ringBell ? 'Zili Çalma.' : ''}${data.leaveToDoor ? 'Kapıya Bırak' : ''}`,
         order_id: order.tracker_code,
         service_type: order.service_type,
         track_token: token,
      };
      setIsLoading(true);
      routeApi()
         .post('/order/update-note/', payload)
         .then((response) => {
            if (response.result) {
               setIsLoading(false);
               handleClose(true);
            }
         })
         .catch((error) => {
            handleClose(false, true);
            setIsLoading(false);
         });
   };

   return (
      <ModalLayout closeModal={handleClose} isModalOpen={isOpen}>
         <div className="modal" style={{ display: isOpen ? 'inline-flex' : 'none', width: 400 }}>
            <div className="optiyol-modal-body" id="optiyol-modal-add-address">
               <div>
                  <h3 className="bold mb-20">{intl.get('changeDeliveryPoint')}</h3>
                  <form id="change-delivery-point-form" onSubmit={handleSubmit(onSubmit)}>
                     {statusOptions
                        .filter((item) => item.active)
                        .map((item) => (
                           <Status
                              status={item.status}
                              selectedStatus={selectedStatus}
                              setSelectedStatus={setSelectedStatus}
                              icon={<i className={`las ${item.iconClassName}`} />}
                              disabled={item.disabled}
                           >
                              {item.extraComponent}
                           </Status>
                        ))}
                     {selectedStatus === 'leaveToLocker' && (
                        <div
                           className="btn cursor-pointer color-p d-block text-center w-100 mt-20 text-14"
                           onClick={() => handleClose(false, false, true)}
                        >
                           {intl.get('selectLocker')}
                        </div>
                     )}
                     <button
                        type="submit"
                        form="change-delivery-point-form"
                        className={`btn cursor-pointer color-p d-block text-center w-100 mt-20 text-14 ${
                           isLoading || selectedStatus === 'leaveToLocker' ? 'btn-disabled' : ''
                        }`}
                        style={{ height: 44 }}
                     >
                        {isLoading ? (
                           <PropagateLoader
                              size={8}
                              color="#fff"
                              css={{
                                 display: 'flex',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                              }}
                           />
                        ) : (
                           intl.get('saveChanges')
                        )}
                     </button>
                  </form>
               </div>
            </div>
            <div className="close-modal cursor-pointer" onClick={() => handleClose()} />
         </div>
      </ModalLayout>
   );
};

export default ChangeDeliveryPointModal;
