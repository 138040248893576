import CryptoJS from 'crypto-js';
export const decryptSocketData = (ciphertext, key) => {
   try {
      const keyParsed = CryptoJS.enc.Base64.parse(key);
      var bytes = CryptoJS.AES.decrypt(ciphertext, keyParsed, { mode: CryptoJS.mode.ECB });
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
   } catch (error) {
      return ciphertext;
   }
};
export const encryptText = (message, key) => {
   const keyParsed = CryptoJS.enc.Base64.parse(key);
   return CryptoJS.AES.encrypt(message, keyParsed, { mode: CryptoJS.mode.ECB }).toString();
};
