/* eslint-disable */
import React, { useEffect, useRef, useState, useMemo } from 'react';
import intl from 'react-intl-universal';
import { toast } from 'react-toastify';
import HereMapContainer from './HereMap/HereMapContainer';
import { homeIcon, lockerIcon, truckIcon } from './HereMap/iconSvg';
import io from 'socket.io-client';
import { centerMapToGroup } from './HereMap/mapFunctions';
import { routeApi } from '../utils/api';
import { useLocation } from 'react-router-dom';
import { StandartModal } from './modals';
import { encryptText, decryptSocketData } from '../utils/helpers';

export function useQuery() {
   const { search } = useLocation();

   return React.useMemo(() => new URLSearchParams(search), [search]);
}
const { H } = window;
const MapBox = ({
   order,
   companyName,
   vehicleUsername,
   isLockerLocationsActive,
   setIsLockerLocationsActive,
   onRefresh,
}) => {
   const newSocket = useMemo(
      () =>
         io(process.env.REACT_APP_NEW_SOCKET_URL, {
            path: '/websocket',
            auth: {
               token: `token ${process.env.REACT_APP_SOCKET_USER_TOKEN}`,
            },
         }),
      []
   );
   const query = useQuery();
   const group = useRef(new H.map.Group());
   const lockerGroup = useRef(new H.map.Group());
   const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(false);
   const [lockerChangeModalOpen, setLockerChangeModalOpen] = useState(false);
   const [selectedLocker, setSelectedLocker] = useState();
   useEffect(() => {
      if (order.lat) {
         group.current.removeObjects(group.current.getObjects());
         createHomeMarker();
         if (order.current_lat) createTruckMarker();
         if (group.current.getObjects().length > 0) centerMapToGroup(group.current);
      }
   }, [order]);
   useEffect(() => {
      if (!isLockerLocationsActive) return;
      const getLockerLocations = async () => {
         const lockers = await routeApi().get(
            `/partner/locker-locations/?partner=direct4me&from_lat=${order.lat}&from_lon=${order.lon}`
         );
         if (lockers && lockers.data && lockers.data.length > 0) createLockerMarkers(lockers.data);
         else {
            toast.error(intl.get('lockerNotFound'));
            setIsLockerLocationsActive(false);
         }
      };
      getLockerLocations();
   }, [isLockerLocationsActive]);
   const createLockerMarkers = (lockers) => {
      lockers.map(({ latitude, longitude, name, address, id, distance, ...rest }) => {
         const m = new H.map.Marker(
            { lat: latitude, lng: longitude },
            {
               icon: new H.map.Icon(lockerIcon('red', '').trim()),
               data: {
                  locker: {
                     name,
                     address,
                     id,
                     distance,
                     latitude,
                     longitude,
                     ...rest,
                  },
               },
            }
         );

         lockerGroup.current.addObject(m);
      });
      window.MapObject.addObject(lockerGroup.current);
      lockerGroup.current.addEventListener('tap', handleSelectLocker);
      return () => {
         lockerGroup.current.removeEventListener('tap', handleSelectLocker);
      };
   };
   const handleSelectLocker = ({ target }) => {
      if (target instanceof H.map.Marker) {
         const lockerData = target.getData().locker;
         setSelectedLocker(lockerData);
      }
   };
   const clearLockers = () => {
      window.MapObject.removeObject(lockerGroup.current);
      setIsLockerLocationsActive(false);
      setSelectedLocker(false);
   };
   const updateLocationAsLocker = async () => {
      try {
         setConfirmBtnDisabled(true);
         const lockerPost = await routeApi().post('/order/update-location/', {
            track_token: query.get('token'),
            customer_order_id: order.tracker_code,
            company_name: companyName,
            service_type: order.service_type,

            is_locker_location: true,
            partner_name: 'direct4me',
            locker_location_id: selectedLocker.id,
            longitude: selectedLocker.longitude,
            latitude: selectedLocker.latitude,
            location_address: selectedLocker.address,
            location_name: selectedLocker.name,
         });
         toast.success(intl.get('lockerSuccess'));
         onRefresh();
         clearLockers();
         setConfirmBtnDisabled(false);
      } catch (error) {
         toast.error(error.response.data.detail);
         clearLockers();
         setConfirmBtnDisabled(false);
      }
   };
   const createHomeMarker = () => {
      const homeMarker = new H.map.Marker(
         { lat: order.lat, lng: order.lon },
         {
            icon: new H.map.Icon(homeIcon('#006DFF').trim()),
         }
      );
      group.current.addObject(homeMarker);
   };
   const createTruckMarker = () => {
      const truck = new H.map.Marker(
         order.current_lat ? { lat: order.current_lat, lng: order.current_lon } : { lat: 41.3, lng: 28.2 },
         {
            icon: new H.map.Icon(truckIcon('#000').trim()),
         }
      );

      group.current.addObject(truck);
      const channel = encryptText(`track-${vehicleUsername}`, process.env.REACT_APP_API_DECYRPT_KEY);
      /* oldSocket.on(channel, (res) => {
         const data = decryptSocketData(res, process.env.REACT_APP_API_DECYRPT_KEY);
         truck.setGeometry({ lat: data.latitude, lng: data.longitude });
      }); */
      newSocket.emit('join-room', vehicleUsername);
      newSocket.on(channel, (res) => {
         const data = decryptSocketData(res, process.env.REACT_APP_API_DECYRPT_KEY);
         truck.setGeometry({ lat: data.latitude, lng: data.longitude });
      });
   };
   return (
      <>
         <HereMapContainer
            onSuccess={(mapObj) => {
               window.MapObject = mapObj;
               window.MapObject.addObject(group.current);
            }}
            zoom={10}
            useEvents
            useUi
         />
         {isLockerLocationsActive && (
            <div className="locker-info absolute-hor-center">
               <div className="fw-500 text-center">{intl.get('lockerSelectionActive')}</div>
               <div className="text-mid-gray text-12 mb-4 text-center">{intl.get('lockerSelectionInfo')}</div>
               <div className="d-flex justify-center align-center">
                  <div
                     className="btn small mr-12 color-p cursor-pointer"
                     onClick={() => centerMapToGroup(lockerGroup.current)}
                  >
                     {intl.get('centerToLockers')}
                  </div>
                  <div className="btn small color-red cursor-pointer" onClick={clearLockers}>
                     {intl.get('cancelLowerCase')}
                  </div>
               </div>
            </div>
         )}
         {isLockerLocationsActive && selectedLocker && (
            <div className="selected-locker absolute-hor-center">
               <div className="d-flex align-center justify-center flex-row mb-12" style={{ flexDirection: 'column' }}>
                  <div className="mr-12 bold" title="Locker Name">
                     <span className="point" />
                     {selectedLocker.name}
                  </div>
                  <div title="Address">{selectedLocker.address}</div>
               </div>
               <div className="btn color-p cursor-pointer" onClick={() => setLockerChangeModalOpen(true)}>
                  {intl.get('orderToLocker')}
               </div>
            </div>
         )}
         {selectedLocker && (
            <StandartModal
               confirmClassName={`color-p ${confirmBtnDisabled ? 'btn-disabled' : ''}`}
               confirmLabel={intl.get('confirm')}
               isOpen={lockerChangeModalOpen}
               handleClose={() => setLockerChangeModalOpen(false)}
               title={intl.get('orderToLocker')}
               subTitle={intl.getHTML('lockerConfirmSubtitle', {
                  lockerName: selectedLocker.name,
               })}
               onConfirm={updateLocationAsLocker}
            />
         )}
      </>
   );
};

export default MapBox;
