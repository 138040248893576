import axios from 'axios';

const defaultOptions = (isFile, companyName = null) => {
   const headers = {
      'Content-Type': isFile ? 'multipart/form-data' : 'application/json',
   };
   headers.Authorization = `Token ${process.env.OPTIYOL_ROUTE_TOKEN}`;
   if (companyName) {
      headers['Optiyol-Company'] = companyName;
   }
   return { headers };
};

const defaultInstance = axios.create({
   baseURL: process.env.REACT_APP_API_URL,
});

export const routeInstance = axios.create({
   baseURL: process.env.REACT_APP_ROUTE_API_URL,
});

// eğer token var ise header'a ekle+
routeInstance.interceptors.request.use((request) => {
   // eğer store'da bulunamazsa asyncstorage'e bak

   request.headers.Authorization = `Token ${process.env.REACT_APP_TRACK_ROUTE_TOKEN}`;

   return request;
});

export const localInstance = axios.create();

export const apiGet = async (url, options = {}, cancelToken, onCancelled = () => {}) => {
   try {
      const results = await defaultInstance.get(url, {
         headers: {
            ...defaultOptions(false).headers,
            ...options.headers,
         },
         params: {
            ...options.params,
         },
         cancelToken,
      });
      if (results.data) return results.data;
      return false;
   } catch (ex) {
      if (axios.isCancel(ex)) onCancelled();
      else console.error(ex);
      // @todo do something smart here cucu
      return false;
   }
};

export const apiPost = async (url, params, options = {}) => {
   try {
      const results = await defaultInstance.post(url, params, {
         ...defaultOptions(false),
         ...options,
      });
      if (results.data) return results.data;
      return false;
   } catch (ex) {
      console.error(ex);
      // @todo do something smart here cucu
      return false;
   }
};

export const apiPostProgress = async (url, params, onUploadProgress, options = {}) => {
   try {
      const results = await defaultInstance.post(url, params, {
         ...defaultOptions(false),
         ...options,
         onUploadProgress,
      });
      if (results.data) return results.data;
      return false;
   } catch (ex) {
      console.error(ex);
      // @todo do something smart here cucu
      return false;
   }
};

export const apiPut = async (url, params, options = {}) => {
   try {
      const results = await defaultInstance.put(url, params, {
         ...defaultOptions(false),
         ...options,
      });
      if (results.data) return results.data;
      return false;
   } catch (ex) {
      console.log(ex);
      // @todo do something smart here cucu
      return false;
   }
};

export const apiDelete = async (url, data, options = {}) => {
   try {
      const results = await defaultInstance.delete(url, {
         data,
         ...defaultOptions(false),
         ...options,
      });
      if (results.data) return results.data;
      return false;
   } catch (ex) {
      console.error(ex);
      // @todo do something smart here cucu
      return false;
   }
};

export const routeApi = () => ({
   get: async (url, options = {}, companyName = null) => {
      const results = await routeInstance.get(url, {
         ...defaultOptions(false, companyName),
         ...options,
      });
      if (results.data) return results.data;
      return false;
   },
   post: async (url, data, options = {}) => {
      const results = await routeInstance.post(url, data, {
         ...defaultOptions(false),
         ...options,
      });
      if (results.data) return results.data;
      return false;
   },

   postFile: (url, data, options = {}) => routeInstance.post(url, data, { ...defaultOptions(true), ...options }),
   patch: (url, options = {}) => routeInstance.patch(url, { ...defaultOptions(false), ...options }),
});

export const localApi = () => ({
   get: (url, options = {}) => localInstance.get(url, { ...defaultOptions(false), ...options }),
   getFile: (url, options = {}) =>
      localInstance.get(url, {
         ...defaultOptions(false),
         responseType: 'arraybuffer',
         ...options,
      }),
   post: (url, data, options = {}) => localInstance.post(url, data, { ...defaultOptions(false), ...options }),
   postFile: (url, data, options = {}) => localInstance.post(url, data, { ...defaultOptions(true), ...options }),
});
