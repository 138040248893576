import intl from 'react-intl-universal';
import React, { useState } from 'react';
import { MobileModalLayout } from '.';
import { routeApi } from '../../utils/api';
import { PropagateLoader } from 'react-spinners';
import { ReactComponent as CancelModalSvg } from '../../assets/cancelModal.svg';

const CancelOrderMobile = ({ handleClose, isOpen, order }) => {
   const [isLoading, setIsLoading] = useState(false);
   const onSubmit = () => {
      setIsLoading(true);
      routeApi()
         .post('/order/cancel-order/', {
            order_id: order.tracker_code,
            service_type: order.service_type,
         })
         .then((res) => {
            setIsLoading(false);
            if (res.result) {
               handleClose(true);
            } else {
               handleClose(false, true);
            }
         })
         .catch((error) => {
            handleClose(false, true);
         });
   };
   return (
      <MobileModalLayout closeModal={handleClose} isModalOpen={isOpen}>
         <div>
            <h3 className="bold mb-20 text-center">{intl.get('cancelDelivery')}</h3>
            <div className="divider-horizontal" />
            <div className="d-flex justify-center align-center mt-20 mb-20">
               <CancelModalSvg />
            </div>
            <div className="text-mid-gray text-center">
               {intl.get('cancelDeliveryModalDesc', { trackerCode: order.tracker_code })}
            </div>
            {isLoading ? (
               <div className="mt-12 d-flex align-center justify-center" style={{ height: 30 }}>
                  <PropagateLoader
                     size={8}
                     color="#000"
                     css={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                     }}
                  />
               </div>
            ) : (
               <div className="d-flex justify-space align-center mt-12">
                  <div
                     className="btn-border color-mid-gray text-black"
                     onClick={() => handleClose()}
                     style={{ padding: '10px 30px' }}
                  >
                     {intl.get('close')}
                  </div>
                  <div className="btn color-red cursor-pointer" onClick={onSubmit} style={{ padding: '10px 30px' }}>
                     {intl.get('cancelDelivery')}
                  </div>
               </div>
            )}
         </div>
      </MobileModalLayout>
   );
};

export default CancelOrderMobile;
