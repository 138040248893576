import React, { useState } from 'react';
import 'react-tabs/style/react-tabs.css';
import intl from 'react-intl-universal';
import { formatDateDayMonthTime } from '../../../utils/date';
import CaptureOutsideClick from '../../../components/captureOutsideClick';

const mapStatus = (status, time, state, serviceType) => {
   switch (status) {
      case 'order.unplanned':
         return { header: intl.get('orderUnplanned') };
      case 'route.planned':
         if (state) return { header: intl.get('routePlanned'), time: formatDateDayMonthTime(time) };
         else return { header: intl.get('routePlanned'), time: '' };
      case 'route.started':
         if (state) return { header: intl.get('routeStarted'), time: formatDateDayMonthTime(time) };
         else return { header: intl.get('routeStarted'), time: '' };
      case 'arrived': {
         if (serviceType === 'delivery') {
            if (state) return { header: intl.get('arrivedToAddress'), time: formatDateDayMonthTime(time) };
            else return { header: intl.get('arrivedToAddress'), time: '' };
         } else if (serviceType === 'pickup') {
            if (state) return { header: intl.get('arrivedToPickUpAddress'), time: formatDateDayMonthTime(time) };
            else return { header: intl.get('arrivedToPickUpAddress'), time: '' };
         }
         break;
      }
      case 'complete':
         if (serviceType === 'delivery') {
            if (state) return { header: intl.get('orderDelivered'), time: formatDateDayMonthTime(time) };
            else return { header: intl.get('orderDelivered'), time: '' };
         } else if (serviceType === 'pickup') {
            if (state) return { header: intl.get('orderPickedUp'), time: formatDateDayMonthTime(time) };
            else return { header: intl.get('orderPickedUp'), time: '' };
         }
         break;
      default:
         return { header: '', time: '' };
   }
};
const VerticalTimeline = ({ orderTimeline }) => (
   <div className="timeline" style={{ overflow: 'scroll', height: 270 }}>
      <div className="timeline-header">{intl.get('orderTimeline')}</div>
      {orderTimeline.map((info, index) => {
         const { header, time } = mapStatus(info.name, info.time, info.state, info.service_type);
         return (
            <div className="row">
               {index < orderTimeline.length - 1 && <i className="las la-ellipsis-v timeline-icon"></i>}
               {info.state ? (
                  <i className="las la-check mr-10 check"></i>
               ) : (
                  <i className="las la-clipboard-check mr-10"></i>
               )}
               <div className="text-14">{header}</div>
               <div className="text-mid-gray text-12 ml-auto">{time}</div>
            </div>
         );
      })}
   </div>
);
const GeneralInformation = ({ order }) => (
   <div className="general-info">
      <div className="header">{intl.get('orderInfo')}</div>
      <InfoItem item={order.customer_name} iconClassName="la-dolly" />
      <InfoItem item={order.tracker_code} iconClassName="la-boxes" />
      <InfoItem item={order.service_type ? intl.get(order.service_type) : ''} iconClassName="la-dolly" />
      <InfoItem item={order.box} iconClassName="la-boxes" />
      <InfoItem item={order.payment_method} iconClassName="la-wallet" />
      <InfoItem item={order.amount} iconClassName="la-wallet" />
      <InfoItem item={order.location_address} iconClassName="la-warehouse" />
   </div>
);
const InfoItem = ({ item, iconClassName }) => {
   return item ? (
      <div className="item">
         <i className={`las icon ${iconClassName}`}></i>
         {item}
      </div>
   ) : (
      <div />
   );
};
const InfoModal = ({ order, timeline }) => {
   const [infoModalOpen, setInfoModalOpen] = useState(false);
   const [selectedTab, setSelectedTab] = useState(1);
   return (
      <CaptureOutsideClick isDetectionActive onOutsideClick={() => setInfoModalOpen(false)}>
         <div className={`mobile-modal with-footer ${infoModalOpen ? 'show' : ''} info-modal `}>
            <div
               className="modal-trigger absolute-hor-center"
               onTouchStart={() => setInfoModalOpen((state) => !state)}
               onClick={() => setInfoModalOpen((state) => !state)}
            >
               <div className="trigger" />
            </div>
            <div className="tabbed-content">
               <div className="tabs">
                  <div className={`tab ${selectedTab === 1 ? 'active' : ''}`} onClick={() => setSelectedTab(1)}>
                     {intl.get('generalInfo')}
                  </div>
                  <div className={`tab ${selectedTab === 2 ? 'active' : ''}`} onClick={() => setSelectedTab(2)}>
                     {intl.get('orderTimeline')}
                  </div>
               </div>
               <div>
                  {selectedTab === 1 ? (
                     <GeneralInformation order={order} />
                  ) : (
                     <VerticalTimeline orderTimeline={timeline} />
                  )}
               </div>
            </div>
         </div>
      </CaptureOutsideClick>
   );
};

export default InfoModal;
