/* eslint-disable */
import intl from 'react-intl-universal';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { MobileModalLayout } from '.';
import { routeApi } from '../../utils/api';
import { PropagateLoader } from 'react-spinners';
import ReactStars from 'react-rating-stars-component';

const FormElement = (question, setFeedbackAnswers, feedbackAnswers, disableForm) => {
   switch (question.type) {
      case 'text':
         return (
            <div className="mt-10 ml-4">
               <div className="mb-4 fw-500 text-14 ">{question.q}</div>
               <textarea
                  onChange={(e) => {
                     let temp = { ...feedbackAnswers };
                     temp[question.q].answer = e.target.value;
                     setFeedbackAnswers(temp);
                  }}
                  disabled={disableForm}
                  className="w-100 p-10"
                  value={feedbackAnswers[question.q]?.answer}
                  required={question.required}
                  style={{
                     fontFamily: 'inherit',
                     borderColor: 'transparent',
                     height: '150px',
                  }}
                  placeholder={intl.get('textPlaceHolder')}
               />
               <div className="divider-horizontal" />
            </div>
         );
      case 'rate':
         return (
            <div className="mt-10 ml-4">
               <div className="mb-4 text-14 fw-500">{question.q}</div>
               <ReactStars
                  key={question.q}
                  count={5}
                  edit={!disableForm}
                  isHalf={false}
                  size={20}
                  onChange={(e) => {
                     let temp = { ...feedbackAnswers };
                     temp[question.q].answer = e;
                     setFeedbackAnswers(temp);
                  }}
                  value={feedbackAnswers[question.q]?.answer}
               />
               <div className="divider-horizontal" />
            </div>
         );
      case 'boolean':
         return (
            <div className="mt-10">
               <div className="mb-4 text-14 ml-4 fw-500">{question.q}</div>
               <input
                  className="form-checkbox"
                  type="checkbox"
                  value={feedbackAnswers[question.q]?.answer}
                  disabled={disableForm}
                  onChange={(e) => {
                     let temp = { ...feedbackAnswers };
                     temp[question.q].answer = e.target.checked;
                     setFeedbackAnswers(temp);
                  }}
                  required={question.required}
               />
               <div className="divider-horizontal" />
            </div>
         );
      default:
         console.warn('no type');
         return null;
   }
};

const FormBuilder = ({ formElemnets, setFeedbackAnswers, feedbackAnswers, disableForm }) => {
   return (
      <div className="custom-scroll" style={{ height: document.body.clientHeight - 270, overflowY: 'scroll' }}>
         {feedbackAnswers &&
            formElemnets.map((item) => FormElement(item, setFeedbackAnswers, feedbackAnswers, disableForm))}
      </div>
   );
};

const FeedbackModalMobile = ({
   handleClose,
   isOpen,
   formList,
   feedbackAnswers,
   setFeedbackAnswers,
   handleSubmit,
   company,
   order,
}) => {
   const [oldAnswers, setOldAnswers] = useState(null);
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      if (!order.route_order_id) return;
      routeApi()
         .get(`/route/order-feedback/${order.route_order_id}/`)
         .then((response) => {
            const temp = { formElements: response.feedback, answersFormat: {} };
            response.feedback.map((item) => {
               temp.answersFormat = { ...temp.answersFormat, [item.q]: item };
            });
            setOldAnswers(temp);
         });
   }, [order]);
   const feedbackSubmit = () => {
      setIsLoading(true);
      const payload = {
         id: order.route_order_id,
         feedback: Object.values(feedbackAnswers),
      };
      routeApi()
         .post('/route/order-feedback/', payload)
         .then((response) => {
            setIsLoading(false);
            if (response) {
               toast.success(intl.get('feedback-saved'));
               handleClose(true);
            } else {
               toast.error(intl.get('errorMessage'));
               handleClose(false, true);
            }
         });
   };
   return (
      <MobileModalLayout closeModal={handleClose} isModalOpen={isOpen}>
         <div>
            <h3 className="bold mb-20">{intl.get('rateDelivery')}</h3>
            {oldAnswers && (
               <div style={{ color: 'red', fontSize: '11px', marginBottom: '4px' }}>
                  {intl.get('feedback-made-already-cant-make-again')}
                  <i className="las la-exclamation-circle" />
               </div>
            )}
            <div className="divider-horizontal" />
            <FormBuilder
               disableForm={oldAnswers ? true : false}
               formElemnets={oldAnswers ? oldAnswers.formElements : formList}
               setFeedbackAnswers={oldAnswers ? setOldAnswers : setFeedbackAnswers}
               feedbackAnswers={oldAnswers ? oldAnswers.answersFormat : feedbackAnswers}
            />
            <div className="divider-horizontal" />
            <div className="w-100 d-flex align-center justify-between mt-10">
               <button
                  className="btn cursor-pointer d-block text-center w-40 text-14"
                  style={{ height: 44 }}
                  onClick={() => handleClose()}
               >
                  <i className="las la-times mr-4 text-16" />
                  {intl.get('cancel')}
               </button>
               <button
                  className={`btn cursor-pointer color-p d-block text-center text-14 ${
                     isLoading || oldAnswers ? 'btn-disabled' : ''
                  }`}
                  style={{ height: 44, width: '50%' }}
                  onClick={() => feedbackSubmit()}
               >
                  {isLoading ? (
                     <PropagateLoader
                        size={8}
                        color="#fff"
                        css={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                        }}
                     />
                  ) : (
                     <div>
                        <i className="las la-check mr-4 text-16" />
                        {intl.get('sendFeedback')}
                     </div>
                  )}
               </button>
            </div>
         </div>
      </MobileModalLayout>
   );
};

export default FeedbackModalMobile;
