export const centerMapToGroup = (group, smooth = false) => {
   const { MapObject } = window;
   if (MapObject) {
      MapObject.getViewPort().resize();
      MapObject.getViewModel().setLookAtData(
         {
            bounds: group.getBoundingBox(),
         },
         smooth
      );
   }
};
