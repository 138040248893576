import intl from 'react-intl-universal';
import React, { useEffect, useState } from 'react';
import { ModalLayout } from '.';
import { routeApi } from '../../utils/api';
import { PropagateLoader } from 'react-spinners';
import { useLocation } from 'react-router-dom';

const AddNoteModal = ({ handleClose, isOpen, handleSubmit, company, order }) => {
   const [note, setNote] = useState('');
   const location = useLocation();
   const [isLoading, setIsLoading] = useState(false);
   useEffect(() => {
      setNote('');
   }, [isOpen]);
   const noteSubmit = () => {
      if (note === '') return;
      const token = location.search && location.search.slice(7);
      const payload = {
         company_id: company.id,
         customer_note: note,
         order_id: order.tracker_code,
         service_type: order.service_type,
         track_token: token,
      };
      setIsLoading(true);
      routeApi()
         .post('/order/update-note/', payload)
         .then((response) => {
            setIsLoading(false);
            if (response.result) {
               handleClose(true);
            } else {
               handleClose(false, true);
            }
         })
         .catch((error) => {
            setIsLoading(false);
            handleClose(false, true);
         });
   };

   return (
      <ModalLayout closeModal={handleClose} isModalOpen={isOpen}>
         <div className="modal" style={{ display: isOpen ? 'inline-flex' : 'none', width: 440 }}>
            <div className="optiyol-modal-body" id="optiyol-modal-add-address">
               <div>
                  <h3 className="bold mb-20">{intl.get('addNoteCamel')}</h3>
                  <div className="divider-horizontal" />
                  <div className="mt-10">
                     <div className="ml-10 mb-4" style={{ color: '#8181A5' }}>
                        {intl.get('note')}
                     </div>
                     <textarea
                        onChange={(e) => setNote(e.target.value)}
                        className="w-100 p-10"
                        value={note}
                        style={{ fontFamily: 'inherit', borderColor: 'transparent', height: '150px' }}
                        placeholder={intl.get('notePlaceHolder')}
                     />
                  </div>
                  <div className="divider-horizontal" />
                  <div className="w-100 d-flex align-center justify-between mt-10">
                     <button
                        className="btn cursor-pointer d-block text-center w-40 text-14"
                        style={{ height: 44 }}
                        onClick={() => handleClose()}
                     >
                        <i className="las la-times mr-4 text-16" />
                        {intl.get('cancel')}
                     </button>
                     <button
                        className={`btn cursor-pointer color-p d-block text-center text-14 ${
                           isLoading ? 'btn-disabled' : ''
                        }`}
                        style={{ height: 44, width: '50%' }}
                        onClick={() => noteSubmit()}
                     >
                        {isLoading ? (
                           <PropagateLoader
                              size={8}
                              color="#fff"
                              css={{
                                 display: 'flex',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                              }}
                           />
                        ) : (
                           <div>
                              <i className="las la-check mr-4 text-16" />
                              {intl.get('sendNote')}
                           </div>
                        )}
                     </button>
                  </div>
               </div>
            </div>
            <div className="close-modal cursor-pointer" onClick={() => handleClose()} />
         </div>
      </ModalLayout>
   );
};

export default AddNoteModal;
