/*eslint-disable */
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import Select from 'react-select';
import { components } from 'react-select';
import store from 'store';

import LocaleEN from '../lang/en.json';
import LocaleTR from '../lang/tr.json';
import LocaleNL from '../lang/nl.json';
import LocaleES from '../lang/es.json';
import LocaleDE from '../lang/de.json';
import LocalePT from '../lang/pt.json';
import LocaleIT from '../lang/it.json';
import LocaleFR from '../lang/fr.json';
import LocaleRU from '../lang/ru.json';
import LocalePL from '../lang/pl.json';
import LocaleID from '../lang/id.json';
import LocaleSV from '../lang/sv.json';
import LocaleCH from '../lang/zh_Hans_CN.json';

const { SingleValue, Option } = components;
const locales = {
   'en-US': LocaleEN,
   'tr-TR': LocaleTR,
   'es-ES': LocaleES,
   'de-DE': LocaleDE,
   'pt-PT': LocalePT,
   'it-IT': LocaleIT,
   'fr-FR': LocaleFR,
   'ru-RU': LocaleRU,
   'pl-PL': LocalePL,
   'zh-CN': LocaleCH,
   'id-ID': LocaleID,
   'sv-SV': LocaleSV,
   'nl-NL': LocaleNL,
};
const Languages = [
   {
      value: 'tr-TR',
      label: 'TR',
      text: 'Türkçe',
   },
   { value: 'en-US', label: 'EN', text: 'English' },
   { value: 'es-ES', label: 'ES', text: 'Español' },
   { value: 'de-DE', label: 'DE', text: 'Deutsch' },
   { value: 'ru-RU', label: 'RU', text: 'Русский' },
   { value: 'pl-PL', label: 'PL', text: 'Polski' },
   { value: 'nl-NL', label: 'NL', text: 'Dutch' },
   { value: 'pt-PT', label: 'PT', text: 'Português' },
   { value: 'it-IT', label: 'IT', text: 'Italiano' },
   { value: 'fr-FR', label: 'FR', text: 'Français' },
   { value: 'sv-SV', label: 'SV', text: 'Svenska' },
   { value: 'id-ID', label: 'ID', text: 'Bahasa Indonesia' },
   { value: 'zh-CN', label: 'CN', text: '中国人' },
];

const IconSingleValue = (props) => (
   <SingleValue {...props}>
      <span className="ml-12">{props.data.text}</span>
   </SingleValue>
);

const IconOption = (props) => (
   <Option {...props}>
      <span className="ml-12">{props.data.text}</span>
   </Option>
);

// Step 3
const customStyles = {
   container: (provided) => ({
      ...provided,
      width: 200,
   }),
   option: (provided) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
   }),
   singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
   }),
};

const LanguageSelect = ({ placement = 'bottom' }) => {
   const [locale, setLocale] = useState(store.get('locale'));

   return (
      <Select
         styles={customStyles}
         components={{
            SingleValue: IconSingleValue,
            Option: IconOption,
            DropdownIndicator: null,
         }}
         options={Languages}
         isSearchable={false}
         menuPlacement={placement}
         noOptionsMessage={() => <span>{intl.get('not-found-option')}</span>}
         DropdownIndicator={null}
         placeholder={null}
         defaultValue={Languages.find((l) => l.value === locale)}
         onChange={(e) => {
            setLocale(e.value);
            store.set('locale', e.value);
            intl.init({ currentLocale: e.value, locales });
            setTimeout(() => {
               window.location.reload();
            }, 500);
         }}
      />
   );
};

export default LanguageSelect;
