import React, { Component } from 'react';

export default class CaptureOutsideClick extends Component {
  // Child componentinin dışına tıklandığında handleClickOutside methodu çalışır
  // props => isDetectionActive propsu ile hangi durumlarda
  // outside click tespit edileceği belirlenir. true-false
  // onOutsideClick propsu ile gönderdiğiniz fonksiyon, outside clickte çalışır.
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const { isDetectionActive, onOutsideClick } = this.props;
    if (isDetectionActive && this.ref.current && !this.ref.current.contains(event.target)) {
      onOutsideClick();
    }
  }

  render() {
    const { children, className, styles, withSpan } = this.props;
    return withSpan ? (
      <span className={className} style={styles} ref={this.ref}>
        {children}
      </span>
    ) : (
      <div className={className} style={styles} ref={this.ref}>
        {children}
      </div>
    );
  }
}
