import React, { useEffect, useRef } from 'react';
import { formatDateDayMonthTime } from '../utils/date';
import intl from 'react-intl-universal';
import LogoIcon from '../assets/logo-icon.svg';
import axios from 'axios';
import LanguageSelect from './languageSelect';
import { Tooltip } from 'react-tooltip';

const mapStatus = (status, time, state, serviceType) => {
   switch (status) {
      case 'order.unplanned':
         return { header: intl.get('orderUnplanned') };
      case 'route.planned':
         if (state) return { header: intl.get('routePlanned'), time: formatDateDayMonthTime(time) };
         else return { header: intl.get('routePlanned'), time: '' };
      case 'route.started':
         if (state) return { header: intl.get('routeStarted'), time: formatDateDayMonthTime(time) };
         else return { header: intl.get('routeStarted'), time: '' };
      case 'arrived': {
         if (serviceType === 'delivery') {
            if (state) return { header: intl.get('arrivedToAddress'), time: formatDateDayMonthTime(time) };
            else return { header: intl.get('arrivedToAddress'), time: '' };
         } else if (serviceType === 'pickup') {
            if (state) return { header: intl.get('arrivedToPickUpAddress'), time: formatDateDayMonthTime(time) };
            else return { header: intl.get('arrivedToPickUpAddress'), time: '' };
         }
         break;
      }
      case 'complete':
         if (serviceType === 'delivery') {
            if (state) return { header: intl.get('orderDelivered'), time: formatDateDayMonthTime(time) };
            else return { header: intl.get('orderDelivered'), time: '' };
         } else if (serviceType === 'pickup') {
            if (state) return { header: intl.get('orderPickedUp'), time: formatDateDayMonthTime(time) };
            else return { header: intl.get('orderPickedUp'), time: '' };
         }
         break;
      default:
         return { header: '', time: '' };
   }
};
const VerticalTimeline = ({ orderTimeline }) => (
   <div className="timeline">
      <div className="timeline-header">{intl.get('orderTimeline')}</div>
      {orderTimeline.map((info, index) => {
         const { header, time } = mapStatus(info.name, info.time, info.state, info.service_type);
         return (
            <div className="row">
               {index < orderTimeline.length - 1 && <i className="las la-ellipsis-v timeline-icon"></i>}
               {info.state ? (
                  <i className="las la-check mr-10 check"></i>
               ) : (
                  <i className="las la-clipboard-check mr-10"></i>
               )}
               <div className="text-14">{header}</div>
               <div className="text-mid-gray text-12 ml-auto">{time}</div>
            </div>
         );
      })}
   </div>
);

const InfoItem = ({ item, iconClassName, title, clipboardCopy }) => {
   return item ? (
      <div className="group-item text-ellipsis" data-tooltip-id="my-tooltip" data-tooltip-content={title}>
         <i className={`las icon ${iconClassName}`}></i>
         <span className="text-ellipsis">{item}</span>
         {clipboardCopy && (
            <i
               className="las la-clipboard text-14 ml-4 cursor-pointer"
               onClick={() => navigator.clipboard.writeText(item)}
            />
         )}
      </div>
   ) : (
      <div />
   );
};

const Infobar = ({ order, timeline, company }) => {
   const imgRef = useRef();
   useEffect(() => {
      if (company.name) {
         axios
            .get(`https://optiyol-mobile-bucket.s3-eu-west-1.amazonaws.com/${company.name.toLowerCase()}/logo.png`)
            .then((res) => {
               if (res.status === 200) {
                  imgRef.current.src = `https://optiyol-mobile-bucket.s3-eu-west-1.amazonaws.com/${company.name.toLowerCase()}/logo.png`;
               }
            });
      }
   }, [company]);
   return (
      <div className="infobar">
         <div className="header">
            <img ref={imgRef} src={LogoIcon} alt="company-logo" /> <div>{intl.get('orderTracking')}</div>
         </div>
         <div className="divider-horizontal" />
         <div className="group">
            <div className="group-header">{intl.get('orderInfo')}</div>
            <div className="row">
               <InfoItem title={intl.get('customer_name')} item={order.customer_name} iconClassName="la-dolly" />
               <InfoItem
                  title={intl.get('orderTrackCode')}
                  item={order.tracker_code}
                  iconClassName="la-dolly"
                  clipboardCopy
               />
            </div>

            <div className="row">
               <InfoItem
                  title={intl.get('service_type')}
                  item={order.service_type ? intl.get(order.service_type) : ''}
                  iconClassName="la-dolly"
               />
               <InfoItem title={intl.get('box')} item={order.box} iconClassName="la-boxes" />
            </div>
            <div className="row">
               <InfoItem title={intl.get('payment_method')} item={order.payment_method} iconClassName="la-wallet" />
               <InfoItem title={intl.get('amount')} item={order.amount} iconClassName="la-wallet" />
            </div>
            <div className="row">
               <div className="w-100">
                  <i className="las la-warehouse text-20" style={{ position: 'absolute' }}></i>
                  <span className="ml-24">{order.location_address}</span>
               </div>
            </div>
         </div>
         <div className="divider-horizontal" />

         <VerticalTimeline orderTimeline={timeline} />
         <div style={{ position: 'absolute', bottom: 10 }}>
            <LanguageSelect placement="top" />
         </div>
         <Tooltip id="my-tooltip" />
      </div>
   );
};

export default Infobar;
