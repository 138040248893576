import React from 'react';
import MapView from './mapView';

const MobileView = ({ data, onRefresh }) => {
   return (
      <MapView
         order={data.result[0]}
         vehicleUsername={data.username}
         timeline={data.status}
         onRefresh={onRefresh}
         company={{
            id: data.company_id,
            name: data.company_name,
         }}
         deliveryPointOptions={data.delivery_point_options}
      />
   );
};

export default MobileView;
