/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import intl from 'react-intl-universal';
import CaptureOutsideClick from '../captureOutsideClick';

export const ModalLayout = ({ isModalOpen, closeModal = () => {}, children, light, zIndex, unmountOnExit = true }) => {
   const handleKeyDown = ({ keyCode }) => {
      if (keyCode === 27) closeModal();
   };
   useEffect(() => {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
         window.removeEventListener('keydown', handleKeyDown);
      };
   }, []);
   return (
      <div
         id="modalBody"
         className={isModalOpen ? `${light ? 'blocker light' : 'blocker'} current` : ''}
         style={{ zIndex }}
      >
         <CSSTransition
            in={isModalOpen}
            timeout={400}
            classNames="optiyol-modal-transition"
            unmountOnExit={unmountOnExit}
         >
            {children}
         </CSSTransition>
      </div>
   );
};

export const MobileModalLayout = ({
   isModalOpen,
   closeModal = () => {},
   children,
   light,
   zIndex,
   unmountOnExit = true,
}) => {
   const handleKeyDown = ({ keyCode }) => {
      if (keyCode === 27) closeModal();
   };
   useEffect(() => {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
         window.removeEventListener('keydown', handleKeyDown);
      };
   }, []);
   return (
      <CaptureOutsideClick isDetectionActive onOutsideClick={() => closeModal()}>
         <div className={`mobile-modal ${isModalOpen ? 'show' : ''}`} style={{ zIndex }}>
            <div className="modal-body">
               <div
                  className="modal-trigger absolute-hor-center"
                  onClick={() => closeModal()}
                  onTouchStart={() => closeModal()}
               >
                  <div className="trigger" />
               </div>
               {children}
            </div>
         </div>
      </CaptureOutsideClick>
   );
};

export const StandartModal = ({
   handleClose,
   isOpen,
   onConfirm,
   children,
   title,
   subTitle,
   confirmLabel,
   confirmClassName = 'color-red',
   modalStyle,
   cancelButton = true,
   row,
}) => (
   <ModalLayout closeModal={handleClose} isModalOpen={isOpen}>
      <div className="modal" style={{ display: isOpen ? 'inline-flex' : 'none', ...modalStyle }}>
         <div className="optiyol-modal-body" id="optiyol-modal-add-address">
            <div className="mb-20">
               <h2 className="bold mb-8">{title}</h2>
               {typeof subTitle === 'function' ? subTitle(row) : <p>{subTitle}</p>}
               {children}
            </div>
            <div className="d-flex row justify-end align-center mt-16">
               {cancelButton && (
                  <div onClick={() => handleClose()} className="btn color-white mr-20 text-14 cursor-pointer">
                     {intl.get('cancel')}
                  </div>
               )}
               <button
                  type="button"
                  style={{ cursor: 'pointer' }}
                  className={`btn text-14 ${confirmClassName}`}
                  onClick={() => onConfirm(row)}
               >
                  {confirmLabel}
               </button>
            </div>
            <div className="close-modal undefined" onClick={() => handleClose()} />
         </div>
      </div>
   </ModalLayout>
);
