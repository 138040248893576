import React, { useEffect, useState } from 'react';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import { MoreActions } from '../../../components/popover';
import AddNoteModal from '../../../components/modals/addNoteModal';
import { routeApi } from '../../../utils/api';
import 'react-toastify/dist/ReactToastify.css';
import intl from 'react-intl-universal';
import ChangeDeliveryPointModal from '../../../components/modals/changeDeliveryPoint';
import { formatDateDayMonthTime } from '../../../utils/date';
import CancelOrderModal from '../../../components/modals/cancelOrderModal';
import FeedbackModal from '../../../components/modals/feedbackModal';
import MapBox from '../../../components/mapBox';

const Header = ({ order }) => (
   <div className="map-header">
      <div className="left ">
         <div>
            <i className="las la-shipping-fast la-3x"></i>
         </div>
         <div>{order.status && intl.get(order.status)}</div>
      </div>
      <div className="right">
         <div className="text-20 bold">{formatDateDayMonthTime(order.estimated_start_time, false)}</div>
         <div className="text-right">{intl.get('estimatedCompleteTime')}</div>
      </div>
   </div>
);

const Footer = ({ setModalOpenState, handleChangeDeliveryPoint, isOrderCompleted, feedbackDisable, disabled }) => (
   <div className="map-footer">
      <div
         className={`btn color-p ml-12 cursor-pointer ${isOrderCompleted || disabled ? 'btn-disabled' : ''}`}
         onClick={handleChangeDeliveryPoint}
      >
         {intl.get('changeDeliveryPoint')}
      </div>
      <div className="btn color-s ml-12 btn-disabled">{intl.get('sendMessage')}</div>
      <MoreActions
         setOpenModal={(i) => setModalOpenState(i)}
         arrow
         isOrderCompleted={isOrderCompleted}
         feedbackDisable={feedbackDisable}
      >
         <div className={`btn color-s ml-12 ${disabled ? 'btn-disabled' : ''}`}>{intl.get('otherOptions')}</div>
      </MoreActions>
   </div>
);

const MapView = ({ order, vehicleUsername, company, onRefresh, deliveryPointOptions }) => {
   const [isChangeDeliveryPointModalOpen, setIsChangeDeliveryPointModalOpen] = useState(false);
   const [isLockerLocationsActive, setIsLockerLocationsActive] = useState(false);
   const [noteModalOpen, setNoteModalOpen] = useState(false);
   const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
   const [modalOpenState, setModalOpenState] = useState(-1);
   const [cancelOrderModalOpen, setCancelOrderModalOpen] = useState(false);
   const [formList, setFormList] = useState(null);
   const [feedbackAnswers, setFeedbackAnswers] = useState(null);
   useEffect(() => {
      switch (modalOpenState) {
         case 1:
            setNoteModalOpen(true);
            break;
         case 4:
            setFeedbackModalOpen(true);
            break;
         case 5:
            setCancelOrderModalOpen(true);
            break;
         default:
            break;
      }
   }, [modalOpenState]);
   useEffect(() => {
      routeApi()
         .get('/company/setting-feedback/', {}, company.name)
         .then((response) => {
            if (response.length > 0 && response[0].value.length > 0) {
               setFormList(response[0].value);
               let temp = {};
               response[0].value.map((item) => {
                  temp = {
                     ...temp,
                     [item.q]: {
                        ...item,
                        answer: item.type === 'boolean' ? false : item.type === 'text' ? '' : 0,
                     },
                  };
               });
               setFeedbackAnswers(temp);
            } else {
               setFeedbackAnswers(null);
               setFormList(null);
            }
         });
   }, [order]);
   return (
      <div className="flex-1 map-view">
         <Header order={order} />
         <div className="pos-rel" style={{ height: 'calc(100vh - 152px)' }}>
            <MapBox
               companyName={company.name}
               order={order}
               vehicleUsername={vehicleUsername}
               isLockerLocationsActive={isLockerLocationsActive}
               setIsLockerLocationsActive={setIsLockerLocationsActive}
               onRefresh={onRefresh}
            />
         </div>
         <ChangeDeliveryPointModal
            isOpen={isChangeDeliveryPointModalOpen}
            deliveryPointOptions={deliveryPointOptions}
            handleClose={(done, error, lockerSelect) => {
               if (lockerSelect) {
                  setIsLockerLocationsActive(true);
                  setIsChangeDeliveryPointModalOpen(false);
                  return;
               }
               if (error) {
                  toast.error(intl.get('errorMessage'));
                  return;
               }
               setIsChangeDeliveryPointModalOpen(false);
               if (done) {
                  toast.success(intl.get('noteAddedMessage'));
               }
            }}
            order={order}
            company={company}
         />
         <AddNoteModal
            order={order}
            company={company}
            isOpen={noteModalOpen}
            handleClose={(done, error) => {
               if (error) {
                  toast.error(intl.get('errorMessage'));
                  return;
               }
               setNoteModalOpen(false);
               setModalOpenState(-1);
               if (done) {
                  toast.success(intl.get('noteAddedMessage'));
               }
            }}
         />
         <FeedbackModal
            isOpen={feedbackModalOpen}
            order={order}
            company={company}
            formList={formList}
            feedbackAnswers={feedbackAnswers}
            setFeedbackAnswers={setFeedbackAnswers}
            handleClose={(done, error) => {
               if (error) {
                  toast.error(intl.get('errorMessage'));
                  return;
               }
               setFeedbackModalOpen(false);
               setModalOpenState(-1);
               if (done) {
                  toast.success(intl.get('feedbackAddedToast'));
               }
            }}
         />

         <CancelOrderModal
            order={order}
            company={company}
            isOpen={cancelOrderModalOpen}
            handleClose={(done, error) => {
               if (error) {
                  toast.error(intl.get('errorMessage'));
                  return;
               }
               setCancelOrderModalOpen(false);
               setModalOpenState(-1);
               if (done) {
                  toast.success(intl.get('orderCanceledMessage'));
                  onRefresh();
               }
            }}
         />
         <Footer
            handleChangeDeliveryPoint={() => setIsChangeDeliveryPointModalOpen(true)}
            setModalOpenState={setModalOpenState}
            isOrderCompleted={order.status === 'completed' || order.status === 'canceled'}
            feedbackDisable={formList}
            disabled={isLockerLocationsActive}
         />
         <ToastContainer
            position="top-center"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            transition={Zoom}
         />
      </div>
   );
};

export default MapView;
